import { Flex, Box } from "@chakra-ui/react";
import {
  Grid,
  GridItem,
  Image,
  Button,
  Heading,
  Text,
  Center,
  useBreakpointValue,
} from "@chakra-ui/react";

const GridPromo = () => {
  const columns = useBreakpointValue({ base: 1, md: 3 });

  const heightGrid = useBreakpointValue({
    base: "250px",
    lg: "300px",
  });

  const widthGrid = useBreakpointValue({
    base: "95%",
    lg: "95%",
  });
  const textResponsive = useBreakpointValue({
    base: "20px",
    md: "30px",
    lg: "40px",
  });

  const textResponsiveRemark = useBreakpointValue({
    base: "20px",
    md: "25px",
  });

  const headerResponsive = useBreakpointValue({
    base: "250px",
    md: "450px",
    lg: "700px",
  });

  const headerResponsiveKLheight = useBreakpointValue({
    base: "250px",
    lg: "500px",
  });

  const headerResponsiveKLwidth = useBreakpointValue({
    base: "100%",
    md: "75%",
  });

  const widthResponsiveHeading = useBreakpointValue({
    base: "100%",
    md: "75%",
    lg: "50%",
  });

  const presentationResponsiveKLheight = useBreakpointValue({
    base: "250px",
    lg: "700px",
  });

  const fontSizeTextFilter = useBreakpointValue({ base: "lg", lg: "2xl" });

  const widthResponsive = useBreakpointValue({ base: "100%", md: "75%" });

  const contentAiFranceEconomy = [
    {
      title: "NEW YORK (NYC)",
      price: "Începând de la 120 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/New York.jpg",
    },
    {
      title: "GUADELOUPE (PTP)",
      price: "Începând de la 578 euro",
      thumbnail:
        "https://travel-time.ro/images/af-kl/AfEconomic/guadeloupe.jpg",
    },
    {
      title: "LOS ANGELES(LAX)",
      price: "Începând de la 320 euro",
      thumbnail:
        "https://travel-time.ro/images/af-kl/AfEconomic/Los Angeles.jpg",
    },
    {
      title: "REUNION(RUN)",
      price: "Începând de la 579 euro",
      thumbnail:
        "https://travel-time.ro/images/af-kl/AfEconomic/la-reunion.jpg",
    },
    {
      title: "BEIJING(BJS)",
      price: "Începând de la 166 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Beijing.jpg",
    },
    {
      title: "PHOENIX(PHX)",
      price: "Începând de la 319 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Phoenix.jpg",
    },
    {
      title: "BOSTON(BOS)",
      price: "Începând de la 120 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfEconomic/Boston.jpg",
    },
    {
      title: "CHICAGO(CHI)",
      price: "Începând de la 120 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Chicago.jpg",
    },
  ];

  const contentAiFrancePremium = [
    {
      title: "CHICAGO(CHI)",
      price: "Începând de la 790 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Chicago.jpg",
    },
    {
      title: "LOS ANGELES(LAX)",
      price: "Începând de la 870 euro",
      thumbnail:
        "https://travel-time.ro/images/af-kl/AfPremium/Los Angeles.jpg",
    },
    {
      title: "NEW YORK(NYC)",
      price: "Începând de la 670 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/New York.jpg",
    },
    {
      title: "TORONTO(YTO)",
      price: "Începând de la 740 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Toronto.jpg",
    },
    {
      title: "LIMA(LIM)",
      price: "Începând de la 1283 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Lima.jpg",
    },
    {
      title: "PANAMA CITY(PTY)",
      price: "Începând de la 748 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/panama.jpg",
    },
    {
      title: "BEIJING(PEK)",
      price: "Începând de la 636 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Beijing.jpg",
    },
    {
      title: "BOGOTA(BOG)",
      price: "Începând de la 935 euro",
      thumbnail: "https://travel-time.ro/images/af-kl/AfPremium/Bogota.jpg",
    },
  ];

  return (
    <>
      <Image
        src="/images/af-kl/airfrance-header.jpg"
        alt="images"
        w="100%"
        h={headerResponsive}
        objectFit="cover"
      />

      <Center
        className="centerText"
        borderBottom="1px solid"
        fontSize={textResponsive}
        fontWeight="bold"
        color="#0a1373"
        textShadow="2px 2px 4px rgba(0, 0, 0)"
        px="6"
        mb="6"
      >
        Air France Economy
      </Center>
      <Flex mt="10px" flexWrap="wrap" justifyContent="center">
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
          {contentAiFranceEconomy.map((image) => {
            return (
              <Box key={image.id} mb={6}>
                <GridItem position="relative" width={widthGrid} mx="auto">
                  <Box
                    className="description-title"
                    mx="auto"
                    p={2}
                    mb={3}
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    display="flex"
                    justifyContent="center"
                    background="#0a1373"
                    color="white"
                    fontWeight="bold"
                    fontSize="1.2rem"
                  >
                    {image.title}
                  </Box>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Image
                      src={image.thumbnail}
                      alt={image.alt}
                      w="100%"
                      h={heightGrid}
                      objectFit="cover"
                    />
                  </a>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Button
                      position="absolute"
                      bottom={0}
                      left={0}
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      {image.price}
                    </Button>
                  </a>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Button
                      position="absolute"
                      bottom={0}
                      right={0}
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      opacity="0.9"
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      Verifică acum!
                    </Button>
                  </a>
                </GridItem>
              </Box>
            );
          })}
        </Grid>
      </Flex>

      <Center
        className="centerText"
        borderBottom="1px solid"
        fontSize={textResponsive}
        fontWeight="bold"
        color="#0a1373"
        textShadow="2px 2px 4px rgba(0, 0, 0)"
        px="6"
        mb="6"
      >
        Air France Premium
      </Center>
      <Flex mt="10px" flexWrap="wrap" justifyContent="center">
        <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={1} w="100%">
          {contentAiFrancePremium.map((image) => {
            return (
              <Box key={image.id} mb={6}>
                <GridItem position="relative" width={widthGrid} mx="auto">
                  <Box
                    className="description-title"
                    mx="auto"
                    p={2}
                    mb={3}
                    boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                    display="flex"
                    justifyContent="center"
                    background="#0a1373"
                    color="white"
                    fontWeight="bold"
                    fontSize="1.2rem"
                  >
                    {image.title}
                  </Box>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Image
                      src={image.thumbnail}
                      alt={image.alt}
                      w="100%"
                      h={heightGrid}
                      objectFit="cover"
                    />
                  </a>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Button
                      position="absolute"
                      bottom={0}
                      left={0}
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      {image.price}
                    </Button>
                  </a>
                  <a href="https://tbs.flytime.ro/reseller/auth/">
                    <Button
                      position="absolute"
                      bottom={0}
                      right={0}
                      size="sm"
                      background="white"
                      // colorScheme="black"
                      color="black"
                      p={3}
                      mb={3}
                      opacity="0.9"
                      mr={3}
                      fontSize="1.2rem"
                      type="button"
                      border="1px"
                      // _hover={{ bg: "white", color: "black" }}
                    >
                      Verifică acum!
                    </Button>
                  </a>
                </GridItem>
              </Box>
            );
          })}
        </Grid>
      </Flex>
      <Center
        className="centerText"
        fontSize={textResponsiveRemark}
        color="#0a1373"
        px="6"
        mb="6"
      >
        * Tarifele nu includ taxe aeroport și ts
      </Center>

      <Image
        src="/images/af-kl/Book now-KL.png"
        alt="images"
        w={headerResponsiveKLwidth}
        mx="auto"
        h={headerResponsiveKLheight}
        objectFit="cover"
      />

      <Box
        borderColor="gray.100"
        borderWidth="2px"
        mt="10px"
        width={widthResponsive}
        mx="auto"
      >
        <Box
          className="heading"
          width="100%"
          mx="auto"
          justifyContent="center"
          alignItems="center"
          p={4}
        >
          <Heading
            width={widthResponsiveHeading}
            mx="auto"
            color="#00d4ff"
            mt="7px"
            textAlign="center"
            size="lg"
            as="h1"
            mb={4}
          >
            Click, book, fly! Următoarea ta aventură este la doar un click
            distanță
            <Image
              src="/images/af-kl/KLM-logo.png"
              alt="images"
              w="400px"
              mx="auto"
              objectFit="cover"
            />
          </Heading>

          <Center
            className="centerText"
            borderBottom="1px solid"
            fontSize={textResponsive}
            fontWeight="bold"
            color="#00d4ff"
            px="6"
            mb="6"
          >
            Premium Comfort
          </Center>
          <Image
            src="/images/af-kl/premium-comfort-kl.jpg"
            alt="images"
            w={headerResponsiveKLwidth}
            mx="auto"
            h={presentationResponsiveKLheight}
            objectFit="cover"
          />
          <Text
            fontSize={fontSizeTextFilter}
            mb={6}
            w={headerResponsiveKLwidth}
            mx="auto"
          >
            În Premium Comfort Class, pasagerii se bucură de un loc confortabil,
            cu mai mult spațiu pentru picioare și o înclinare suplimentară.
            Cabina este liniștită, cu doar 21-28 de locuri, separată complet de
            clasele Economy și Business. Scaunele oferă până la 17 cm în plus
            pentru picioare, o înclinare de până la 20 cm și un ecran personal
            de 13 inch.
          </Text>
          <Center
            className="centerText"
            borderBottom="1px solid"
            fontSize={textResponsive}
            fontWeight="bold"
            color="#00d4ff"
            px="6"
            mb="6"
          >
            Economy
          </Center>
          <Image
            src="/images/af-kl/economy-kl.jpg"
            alt="images"
            w={headerResponsiveKLwidth}
            mx="auto"
            h={presentationResponsiveKLheight}
            objectFit="cover"
          />
          <Text
            fontSize={fontSizeTextFilter}
            mb={6}
            w={headerResponsiveKLwidth}
            mx="auto"
          >
            În clasa Economy, pasagerii au posibilitatea de a-și rezerva locul
            preferat încă de la momentul rezervării zborului. La check-in, pot
            alege un loc standard gratuit, însă pentru mai mult confort sau un
            loc situat în fața avionului, se poate face o rezervare în avans.
          </Text>
          <Center
            className="centerText"
            borderBottom="1px solid"
            fontSize={textResponsive}
            fontWeight="bold"
            color="#00d4ff"
            px="6"
            mb="6"
          >
            Bagaj de mână inclus
          </Center>
          <Image
            src="/images/af-kl/KLM-hand luggage.jpg"
            alt="images"
            w={headerResponsiveKLwidth}
            mx="auto"
            h={presentationResponsiveKLheight}
            objectFit="cover"
          />
          <Text
            fontSize={fontSizeTextFilter}
            mb={6}
            w={headerResponsiveKLwidth}
            mx="auto"
          >
            Pentru zborurile KLM, pasagerii pot aduce bagaje de mână conform
            dimensiunilor și greutății permise. În clasa Economy, este permis un
            bagaj de mână și un accesoriu cu o greutate totală de până la 12 kg.
            În clasa Premium Comfort, sunt permise două bagaje de mână și un
            accesoriu, cu aceeași limită de greutate. Asigurați-vă că bagajele
            respectă dimensiunile specificate.
          </Text>
          <Center
            className="centerText"
            borderBottom="1px solid"
            fontSize={textResponsive}
            fontWeight="bold"
            color="#00d4ff"
            px="6"
            mb="6"
          >
            Meniu la bordul aeronavelor KLM
          </Center>
          <Image
            src="/images/af-kl/meal-kl.jpg"
            alt="images"
            w={headerResponsiveKLwidth}
            mx="auto"
            h={presentationResponsiveKLheight}
            objectFit="cover"
          />
          <Text
            fontSize={fontSizeTextFilter}
            mb={6}
            w={headerResponsiveKLwidth}
            mx="auto"
          >
            În clasa Economy, pasagerii pot savura o varietate de gustări și
            mese, adaptate în funcție de durata zborului. Pe zborurile europene,
            sunt oferite gustări și băuturi, iar pe cele mai lungi, și un
            sandwich proaspăt. Pe zborurile intercontinentale, pasagerii primesc
            o masă completă cu opțiuni vegetariene. Pentru o experiență
            personalizată, se pot comanda meniuri à la carte, disponibile pe
            zborurile din Amsterdam.
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default GridPromo;
