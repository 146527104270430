import React from "react";
import { Flex, Box, Image, Center, Button } from "@chakra-ui/react";
import airlineLogos from "../../airlinesLogo";
import { Link } from "react-router-dom";

const AirlineLogos = () => {
  return (
    <>
      <Center
        justify="center"
        align="center"
        as="h1"
        fontSize={{ base: "2xl", md: "3xl" }}
        mt="15px"
      >
        Peste 130 de companii aeriene in cel mai complet sistem de rezervări din
        România .
      </Center>
      <Center
        justify="center"
        align="center"
        as="h1"
        fontSize={{ base: "2xl", md: "3xl" }}
        mt="15px"
      >
        <Link to="https://tbs.flytime.ro/reseller/auth/">
          <Button
            fontSize="20px"
            target="_blank"
            colorScheme="red"
            variant="outline"
            mr={4}
            color="red"
            bg="white.400"
            borderRadius="25px"
            _hover={{
              boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
            }}
          >
            Descoperă Acum!
          </Button>
        </Link>
      </Center>
      <Flex
        direction="row"
        justify="center"
        align="center"
        wrap="wrap"
        maxW={{ base: "90%", md: "50%" }}
        mx="auto"
        mt={8}
      >
        {airlineLogos.map((airline) => (
          <Box key={airline.id} p={4}>
            <Image
              src={airline.imageUrl}
              alt={airline.name}
              maxW={{ base: "85px", md: "120px" }}
            />
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default AirlineLogos;
