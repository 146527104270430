import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import AirlineLogos from "../../components/airlinesLogo/AirlinesLogo";
import Objectives from "../../components/objectives/Objectives";
import ReservationsSection from "../../components/reservationInfo/Reservation2";
import PresentationSection from "../../components/presentation/Presentantion";
import ContactUs from "../../components/contact-us/ContactUs";
import Footer from "../../components/footer/Footer";
import FormContact from "../../components/form-contact/formcontact";
import Hotels from "../../components/Reservation-details/Hotels";
import Ticketing from "../../components/Reservation-details/Ticketing";
import CarouselHomePage from "../../components/carousel/carousel-corporate";
import GridPromo from "../../components/grid-promo/grid-promo";

const Home = () => {
  return (
    <>
      <Navbar />
      {/* <CarouselHomePage /> */}
      <GridPromo />
      <Header />
      <AirlineLogos />
      <Objectives />
      <ReservationsSection />
      <Ticketing />
      <Hotels />
      <PresentationSection />
      <ContactUs />
      <FormContact />
      <Footer />
    </>
  );
};

export default Home;
